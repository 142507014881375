//import global API
import { Api } from "../../api/Api";

const monitoring = {
   namespaced: true,
   state: {
      listabodemen: [],
      spending: [],
      transaksi: [],
      empty: "",
   },
   mutations: {
      SET_ABDODEMENT_PT(state, list) {
         state.listabodemen = list;
      },
      SET_SPENDING(state, list) {
         state.spending = list;
      },
      SET_TRANSAKSI(state, list) {
         state.spending = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      getAbodemen({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`perusahaan/abonemen?page=${data.page}&search=${data.search}&produk=${data.produk}`)
               .then((response) => {
                  const list = response.data;
                  commit("SET_ABDODEMENT_PT", response.data.data);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      transaksi({ commit }, data) {
            return new Promise((resolve, reject) => {
               Api.get(`top-up?search=${data.search}&page=${data.page}`)
                  .then((response) => {
                     const list = response.data;
                     commit("SET_TRANSAKSI", response.data.data);
                     resolve(list);
                  })
                  .catch((error) => {
                     reject(error);
                  });
            });
      },
      spending({ commit }, data) {
            return new Promise((resolve, reject) => {
               Api.get(`spending?search=${data.search}&page=${data.page}&dari_tanggal=${data.dari_tanggal}&sampai_tanggal=${data.sampai_tanggal}`)
                  .then((response) => {
                     const list = response.data;
                     commit("SET_SPENDING", response.data.data);
                     resolve(list);
                  })
                  .catch((error) => {
                     reject(error);
                  });
            });
      },
   },
   getters: {
      c_abodemen(state) {
         return state.listabodemen;
      },
   },
};
export default monitoring;
