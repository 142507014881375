const mixins = {
   methods: {
      /**
       *
       * @param {*} value
       */
      formatPrice(value) {
         let val = (value / 1).toFixed(0).replace(".", ",");
         return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      },

      /**
       *
       * @param {*} partialValue
       * @param {*} totalValue
       */
      percentage(partialValue, totalValue) {
         return (100 * partialValue) / totalValue;
      },

      /**
       *
       * @param {*} maxDate
       */
      maxDate(maxDate) {
         let date = new Date();
         let now = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);

         //if same date
         if (now == maxDate) {
            return true;
         }

         //if now > max date
         if (now > maxDate) {
            return true;
         }
         return false;
      },

      /**
       *
       * @param {*} maxDate
       */
      countDay(maxDate) {
         let date = new Date();
         let now = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);

         let dt1 = new Date(now);
         let dt2 = new Date(maxDate);

         let result = Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));

         if (result < 0) {
            return 0;
         }
         return result;
      },

      /* Pagination Navigation */
      checkPrevious(id) {
         if (id > 1) {
            return id - 1;
         }
         return 1;
      },

      /* Pagination Navigation */
      checkNext(id, pageCount) {
         if (id < pageCount) {
            return id + 1;
         }
         return id;
      },

      /* Page Range calculation Method for Pagination */
      pageRange(page, pageCount) {
         var start = page - 2,
            end = page + 2;

         if (end > pageCount) {
            start -= end - pageCount;
            end = pageCount;
         }

         if (start <= 0) {
            end += (start - 1) * -1;
            start = 1;
         }

         end = end > pageCount ? pageCount : end;

         return {
            start: start,
            end: end,
         };
      },
      onlyNumber(nomor) {
         nomor = nomor.replace(/\D/g, '');
         return nomor
      },
   },
};
export default mixins;
