//import global API
import { Api } from "../../api/Api";

const utility = {
   namespaced: true,
   state: {
      list_otp: [],
      list_log: [],
      empty: "",
   },
   mutations: {
      SET_OTP(state, list) {
         state.list_otp = list;
      },
      SET_LOG(state, list) {
         state.list_log = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      list_otp({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`otp?page=${data.page}&per_page=${data.per_page}&key=${data.search}`)
               .then((response) => {
                  commit("SET_OTP", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getlogs({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`request-log?page=${data.page}&search=${data.search}&path=${data.path}&status_code=${data.status_code}&method=${data.method}&start=${data.start}&end=${data.end}`)
               .then((response) => {
                  commit("SET_LOG", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      create({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`mitra`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      g_otp(state) {
         return state.list_otp;
      },
      g_logs(state) {
         return state.list_log;
      },
   },
};
export default utility;
