import { onMounted, computed } from "vue";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { useLoading } from "vue-loading-overlay";
import * as te from "tw-elements";

export default {
   name: "SidebarComponents",
   setup() {
      const $loading = useLoading({});
      const toast = useToast();
      const store = useStore();
      const router = useRouter();

      function handle() {
         const sidenav = document.getElementById("full-screen-example");
         const sidenavInstance = new te.Sidenav(sidenav);
         let innerWidth = null;
         const setMode = () => {
            if (window.innerWidth === innerWidth) {
               return;
            }
            innerWidth = window.innerWidth;
            if (window.innerWidth < sidenavInstance.getBreakpoint("sm")) {
               sidenavInstance.changeMode("over");
               sidenavInstance.hide();
            } else {
               sidenavInstance.changeMode("side");
               sidenavInstance.show();
            }
         };
         setMode();
         window.addEventListener("resize", setMode);
      }

      const profile = computed(() => {
         return store.state.auth.user ? JSON.parse(store.state.auth.user) : JSON.parse(window.localStorage.getItem("user"));
      });

      function logout() {
         Swal.fire({
            icon: "info",
            title: "apa anda yakin ingin keluar dari sistem cms ?",
            showDenyButton: true,
            confirmButtonText: "Ya, saya yakin",
            denyButtonText: `Tidak jadi`,
         }).then((result) => {
            if (result.isConfirmed) {
               const loader = $loading.show();
               store
                  .dispatch("auth/logout")
                  .then(() => {
                     toast.success("berhasil logout!");
                     loader.hide();
                     router.push({ name: "login" });
                  })
                  .catch((error) => {
                     console.log(error);
                  });
            } else if (result.isDenied) {
               console.log("cancel");
            }
         });
      }

      onMounted(() => {
         handle();
      });

      return {
         logout,
         profile,
      };
   },
};
