//import global API
import { Api } from "../../api/Api";

const auth = {
    namespaced: true,
    state: {
        token: localStorage.getItem('token') || '',
        user: localStorage.getItem('user') || {},
        id: localStorage.getItem('id') || null
    },
    mutations: {
        AUTH_SUCCESS(state, token, user) {
            state.token = token
            state.user = user
        },
        AUTH_USER(state, user) {
            state.user = user
        },
        AUTH_LOGOUT(state) {
            state.token = ''
            state.user = {}
        },

    },
    actions: {
        logout({ commit }) {
            return new Promise((resolve) => {
                commit('AUTH_LOGOUT')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                delete Api.defaults.headers.common['Authorization']
                resolve('')
            })
        },
        login({ commit }, user) {
            return new Promise((resolve, reject) => {
                Api.post('auth/login', user)

                    .then(response => {
                        const token = response.data.access_token
                        const user = response.data

                        localStorage.setItem('token', token)
                        localStorage.setItem('user', JSON.stringify(user))
                        localStorage.setItem('id', response.data.id)

                        Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                        commit('AUTH_SUCCESS', token, user)

                        resolve(response.data)
                    }).catch(error => {
                        localStorage.removeItem('token')
                        reject(error)

                    })

            })

        },
    },
    getters: {
        isLoggedIn(state) {
            return state.token
        },
        user(state) {
            return state.user
        }
    }

}

export default auth