//import global API
import { Api } from "../../api/Api";

const master = {
   namespaced: true,
   state: {
      provinsi: [],
      kota: [],
      kota_by: [],
      kecamatan: [],
      empty: "",
   },
   mutations: {
      SET_KOTA(state, data) {
         state.kota = data;
      },
      SET_KOTA_BY(state, data) {
         state.kota_by = data;
      },
      SET_PROVINSI(state, data) {
         state.provinsi = data;
      },
      SET_KECAMATAN(state, data) {
         state.kecamatan= data;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      kota({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`kota?page=${data.page}&search=${data.search}&id_provinsi=${data.id_provinsi}&is_paginate=${data.is_paginate}`)
               .then((response) => {
                  commit("SET_KOTA", response.data.data);
                  commit("SET_KOTA_BY", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      kecamatan({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`kecamatan?page=${data.page}&search=${data.search}&id_provinsi=${data.id_provinsi}&is_paginate=${data.is_paginate}&id_kota=${data.id_kota}`)
               .then((response) => {
                  commit("SET_KECAMATAN", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update_kota({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.put(`kota/${data.id_kota}`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update_provinsi({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.put(`provinsi/${data.id_provinsi}`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update_kecamatan({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.put(`kecamatan/${data.id_kecamatan}`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      provinsi({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`provinsi?page=${data.page}&search=${data.search}&is_paginate=${data.is_paginate}`)
               .then((response) => {
                  commit("SET_PROVINSI", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      add_kota({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`kota`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      add_kecamatan({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`kecamatan`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      add_provinsi({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`provinsi`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      get_kota(state) {
         return state.kota;
      },
      get_kota_by(state) {
         return state.kota;
      },
      get_provinsi(state) {
         return state.provinsi;
      },
      get_kecamatan(state) {
         return state.kecamatan;
      },
   },
};
export default master;
