//import global API
import { Api } from "../../api/Api";

const mitra = {
   namespaced: true,
   state: {
      listMitra: [],
      mitraNested: [],
      empty: "",
   },
   mutations: {
      ADD_SUCCESS(state, list) {
         state.listMitra = list;
      },
      ADD_MITRANESTED(state, list) {
         state.mitraNested = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      listMitra({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`mitra?page=${data.page}&search=${data.search}`)
               .then((response) => {
                  const list = response.data;
                  const list_siswa = response.data.data;
                  commit("ADD_SUCCESS", list_siswa);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      listMitraNested({ commit }) {
         return new Promise((resolve, reject) => {
            Api.get(`mitra/nestable/all`)
               .then((response) => {
                  console.log(response.data.data)
                  commit("ADD_MITRANESTED", response.data.data);
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      create({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`mitra`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`mitra/${data.id}`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      updateUrutan({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`mitra/nestable/update`, data.urutan)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      hapusMitra({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`mitra/delete/${data.id}}`)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      list(state) {
         return state.listMitra;
      },
      list_nested(state) {
         return state.mitraNested;
      },
   },
};
export default mitra;
