//import global API
import { Api, Api2 } from "../../api/Api";

const webinar = {
   namespaced: true,
   state: {
      webinar: [],
      detail_webinar: {},
      peserta: [],
      kategori: [],
      empty: "",
   },
   mutations: {
      ADD_SUCCESS(state, list) {
         state.webinar = list;
      },
      ADD_PESERTA_WEBINAR(state, peserta) {
         state.peserta = peserta;
      },
      ADD_DETAIL_WEBINAR(state, detail_webinar) {
         state.detail_webinar = detail_webinar;
      },
      ADD_KATEGORI(state, kategori) {
         state.kategori = kategori;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      get_webinar({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`list-webinar?page=${data.page}&search=${data.search}&status=${data.search}`)
               .then((response) => {
                  commit("ADD_SUCCESS", response.data.data.result);
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      get_detail({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`webinar/detail/?id_webinar=${data.id}`)
               .then((response) => {
                  commit("ADD_DETAIL_WEBINAR", response.data.data);
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      get_peserta_webinar({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`webinar/peserta?id_webinar=${data.id}&page=${data.page}&status=${data.status}`)
               .then((response) => {
                  commit("ADD_PESERTA_WEBINAR", response.data.data.result.data);
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      listKategori({ commit }) {
         return new Promise((resolve, reject) => {
            Api2.get(`landing/get-kategori`)
               .then((response) => {
                  commit("ADD_KATEGORI", response.data.data);
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      exportpeserta({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.defaults.responseType = "blob"
            Api.get(`webinar/export-peserta?id_webinar=${data.id}&status=${data.status}&per_page=999`)
               .then((response) => {
                  commit("EMPTY", '');
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      create({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`add-webinar`, data)
               .then((response) => {
                  commit("EMPTY", '');
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`webinar/update/`, data)
               .then((response) => {
                  commit("EMPTY", '');
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      ubahstatus({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`${data.link}`, data)
               .then((response) => {
                  commit("EMPTY", '');
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      webinar(state) {
         return state.webinar;
      },
      peserta(state) {
         return state.peserta;
      },
      kategori(state) {
         return state.kategori;
      },
      detail_webinar(state) {
         return state.detail_webinar;
      },
   },
};
export default webinar;
