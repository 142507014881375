//import global API
import { Api } from "../../api/Api";
// import { Api, Api2 } from "../../api/Api";
// import Api2 from "../../api/Api";

const notification = {
  namespaced: true,
  state: {
    list_notification_image: [],
    list_lowongan: [],
  },
  mutations: {
    ADD_SUCCESS(state, list) {
      state.list_notification_image = list;
      state.list_lowongan = list;
    },
    EMPTY(state, string) {
      state.empty = string;
    },
  },
  actions: {
    blastInfo({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.post(`notification/info`, data)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    blastEmail({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.post(`notification/berita`, data)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addImage({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.post(`notification/image`, data)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    tambahLowongan({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.post(`lowongan`, data)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    blastNotification({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.post(`notification/info`, data)
          .then(() => {
            commit("EMPTY", "");
            resolve("");
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    tutupLowongan({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.put(`lowongan/status/${data.id_lowongan}`)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    create_payment({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.post(`payment/create`, data)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    list({ commit }) {
      return new Promise((resolve, reject) => {
        Api.get(`notification/image`)
          .then((response) => {
            const list = response.data;
            const list_notification_image = response.data.data;
            commit("ADD_SUCCESS", list_notification_image);
            resolve(list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    lowongan({ commit }, data) {
      return new Promise((resolve, reject) => {
        // Api2.get(`industry/get-list-lowongan?page=${data.page}&per_page=10&search=${data.search}`)
        Api.get(
          `perusahaan/lowongan?page=${data.page}&per_page=10&search=${data.search}&is_active=${data.is_active}&start_date=${data.start_date}&end_date=${data.end_date}`
        )
          .then((response) => {
            const list = response.data;
            const list_lowongan = response.data.data.result;
            commit("ADD_SUCCESS", list_lowongan);
            resolve(list);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getbidang() {
      return new Promise((resolve, reject) => {
        Api.get(`bidang`)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getKeahlian() {
      return new Promise((resolve, reject) => {
        Api.get(`keahlian`)
          .then((response) => {
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    posisiPekerjaan({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.get(`lowongan/posisi-pekerjaan?search=${data.search}`)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAlamat({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.get(`perusahaan/alamat?id_perusahaan=${data.id_perusahaan}`)
          .then((response) => {
            commit("EMPTY", "");
            resolve(response.data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    exportLowongan({ commit }, data) {
      return new Promise((resolve, reject) => {
        Api.defaults.responseType = "blob";
        Api.get(
          `lowongan/export?status=${data.status}&start_date=${data.start_date}&end_date=${data.end_date}`
        )
          .then((response) => {
            commit("EMPTY", "");
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {},
};
export default notification;
