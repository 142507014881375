//import global API
import { Api } from "../../api/Api";

const sekolah = {
   namespaced: true,
   state: {
      list_sekolah: [],
      empty: "",
   },
   mutations: {
      ADD_SUCCESS(state, list) {
         state.list_sekolah = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      list({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`sekolah?page=${data.page}&search=${data.search}&is_active=${data.is_active}&level=${data.level}&last_login=${data.status_login}`)
               .then((response) => {
                  const list = response.data;
                  const list_sekolah = response.data.data.result;
                  commit("ADD_SUCCESS", list_sekolah);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      ubah_status_aktif({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`sekolah/${data.link}`, {
               id_sekolah: data.id_sekolah,
            })
               .then((response) => {
                  commit("ADD_SUCCESS", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      list(state) {
         return state.list_sekolah;
      },
   },
};
export default sekolah;
