//import global API
import { Api } from '../../api/Api'

const verifikasiAkun = {
    namespaced: true,
    state: {
        list: [],
        empty: ''
    },
    mutations: {
        EMPTY(state, string) {
            state.empty = string
        },
        ADD_SUCCESS(state, list) {
            state.list = list
        },
    },
    actions: {
        verifikasiAkun({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`verifikasi?page=${data.page}&search=${data.search}&status=${data.status}&type_user=${data.type_user}&start_range=${data.start_range}&end_range=${data.end_range}`).then(response => {
                    commit('EMPTY', '')
                    const list = response.data.data.data
                    const data = response.data.data
                    commit('ADD_SUCCESS', list)
                    resolve(data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        approve({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`verifikasi`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        reject({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`verifikasi/reject`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
    }

}
export default verifikasiAkun