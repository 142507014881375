import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      title: "Login",
      requiresAuth: false,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/dashboard/Dashboard.vue"),
    meta: {
      title: "Dashboard",
      requiresAuth: true,
    },
  },
  {
    path: "/otp",
    name: "otp",
    component: () => import("@/views/otp/index.vue"),
    meta: {
      title: "OTP List",
      requiresAuth: true,
    },
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/Payment.vue"),
    meta: {
      title: "Buat custom payments",
      requiresAuth: true,
    },
  },
  {
    path: "/provinsi",
    name: "provinsi",
    component: () => import("@/views/master/Provinsi.vue"),
    meta: {
      title: "Kelola Provinsi",
      requiresAuth: true,
    },
  },
  {
    path: "/kota",
    name: "kota",
    component: () => import("@/views/master/Kota.vue"),
    meta: {
      title: "Kelola Kota",
      requiresAuth: true,
    },
  },
  {
    path: "/data-ekrutes",
    name: "data ekrutes",
    component: () => import("@/views/data-ekrutes/DataEkrutes.vue"),
    meta: {
      title: "Kelola Kota",
      requiresAuth: true,
    },
  },
  {
    path: "/kecamatan",
    name: "kecamatan",
    component: () => import("@/views/master/Kecamatan.vue"),
    meta: {
      title: "Kelola Kecamatan",
      requiresAuth: true,
    },
  },
  {
    path: "/transaksi",
    name: "transaksi-user",
    component: () => import("@/views/transaksi/Index.vue"),
    meta: {
      title: "History Transaksi User",
      requiresAuth: true,
    },
  },
  {
    path: "/spending",
    name: "spending-user",
    component: () => import("@/views/spending/Index.vue"),
    meta: {
      title: "History Spending User",
      requiresAuth: true,
    },
  },
  {
    path: "/atur-promo",
    name: "atur-promo",
    component: () => import("@/views/promo/Index.vue"),
    meta: {
      title: "Atur Promo",
      requiresAuth: true,
    },
  },
  {
    path: "/suscription-expired-soon",
    name: "subs-expired-soon",
    component: () => import("@/views/monitoring/subs_expired/Index.vue"),
    meta: {
      title: "Subscription Segera Habis",
      requiresAuth: true,
    },
  },
  {
    path: "/ekrutes-log",
    name: "ekrutes-log",
    component: () => import("@/views/logs/Index.vue"),
    meta: {
      title: "EKRUTES LOGS",
      requiresAuth: true,
    },
  },
  {
    path: "/mini-quiz",
    name: "miniquiz",
    component: () => import("@/views/mini_quiz/Index.vue"),
    meta: {
      title: "Mini Quiz",
      requiresAuth: true,
    },
  },
  {
    path: "/upload-soal/:id",
    name: "uploadsoal",
    component: () => import("@/views/upload_soal/Index.vue"),
    meta: {
      title: "Upload Soal",
      requiresAuth: true,
    },
  },
  {
    path: "/verifikasi-akun",
    name: "verifikasiakun",
    component: () => import("@/views/verifikasiAkun/Index.vue"),
    meta: {
      title: "Verifikasi Akun",
      requiresAuth: true,
    },
  },
  {
    path: "/talent",
    name: "talent",
    component: () => import("@/views/user/talent/Index.vue"),
    meta: {
      title: "Talent Management",
      requiresAuth: true,
    },
  },
  {
    path: "/hr",
    name: "hr",
    component: () => import("@/views/user/hr/Index.vue"),
    meta: {
      title: "HR Management",
      requiresAuth: true,
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/views/user/admin/Index.vue"),
    meta: {
      title: "Admin Management",
      requiresAuth: true,
    },
  },
  {
    path: "/sekolah",
    name: "sekolah",
    component: () => import("@/views/user/sekolah/Index.vue"),
    meta: {
      title: "Sekolah Management",
      requiresAuth: true,
    },
  },
  {
    path: "/siswa",
    name: "siswa",
    component: () => import("@/views/user/siswa/Index.vue"),
    meta: {
      title: "Siswa Management",
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("@/views/profile/Index.vue"),
    meta: {
      title: "Profile",
      requiresAuth: true,
    },
  },
  {
    path: "/notification",
    name: "notification",
    component: () => import("@/views/notification/Index.vue"),
    meta: {
      title: "notification",
      requiresAuth: true,
    },
  },
  {
    path: "/upload-gambar",
    name: "uploadgambar",
    component: () => import("@/views/notification/upload_gambar/Index.vue"),
    meta: {
      title: "Upload Gambar",
      requiresAuth: true,
    },
  },
  {
    path: "/lowongan",
    name: "lowongan",
    component: () => import("@/views/notification/Lowongan.vue"),
    meta: {
      title: "Lowongan",
      requiresAuth: true,
    },
  },
  {
    path: "/list-soal/:id/:skoring",
    name: "soalquiz",
    component: () => import("@/views/soal/Index.vue"),
    meta: {
      title: "list soal",
      requiresAuth: true,
    },
  },
  {
    path: "/capture",
    name: "capture",
    component: () => import("@/views/capture/Index.vue"),
    meta: {
      title: "capture sesi tes peserta",
      requiresAuth: true,
    },
  },
  {
    path: "/add-soal/:skoring",
    name: "add-soal",
    component: () => import("@/views/soal/add-soal.vue"),
    beforeRouteLeave(to, from, next) {
      const shouldLeave = window.confirm(
        "Are you sure you want to leave this page?"
      );
      if (shouldLeave) {
        next();
      } else {
        next(false);
      }
    },
    meta: {
      title: "buat soal",
      requiresAuth: true,
    },
  },
  {
    path: "/skoring/:id",
    name: "skoring",
    component: () => import("@/views/skoring/Index.vue"),
    meta: {
      title: "skoring",
      requiresAuth: true,
    },
  },
  {
    path: "/mitra",
    name: "mitra",
    component: () => import("@/views/mitra/Index.vue"),
    meta: {
      title: "kelola mitra",
      requiresAuth: true,
    },
  },
  {
    path: "/atur-mitra",
    name: "aturmitra",
    component: () => import("@/views/mitra/Atur.vue"),
    meta: {
      title: "atur urutan mitra ekrutes",
      requiresAuth: true,
    },
  },
  {
    path: "/talent-development",
    name: "talentdevelopment",
    component: () => import("@/views/talent-development/Index.vue"),
    meta: {
      title: "talent-development",
      requiresAuth: true,
    },
  },
  {
    path: "/talent-development/peserta/:id",
    name: "talentdevelopmentpeserta",
    component: () => import("@/views/talent-development/Peserta.vue"),
    meta: {
      title: "talent-development-peserta",
      requiresAuth: true,
    },
  },
  {
    path: "/talent-development/feedback/:id",
    name: "talentdevelopmentfeedback",
    component: () => import("@/views/talent-development/Feedback.vue"),
    meta: {
      title: "talent-development-peserta",
      requiresAuth: true,
    },
  },
  {
    path: "/peserta-webinar/:id",
    name: "pesertawebinar",
    component: () => import("@/views/webinar/Peserta.vue"),
    meta: {
      title: "peserta-webinar",
      requiresAuth: true,
    },
  },
  {
    path: "/sesi-tes-perusahaan/:id_perusahaan",
    name: "sesi-tes-perusahaan-export",
    component: () => import("@/views/user/hr/Sesi.vue"),
    meta: {
      title: "peserta-webinar",
      requiresAuth: true,
    },
  },
  {
    path: "/webinar",
    name: "webinar",
    component: () => import("@/views/webinar/Index.vue"),
    meta: {
      title: "webinar",
      requiresAuth: true,
    },
  },
  {
    path: "/kupon",
    name: "kupon",
    component: () => import("@/views/kupon/index.vue"),
    meta: {
      title: "kupon",
      requiresAuth: true,
    },
  },
  {
    path: "/daftar-sesi-tes-perusahaan",
    name: "sesi-tes-perusahaan",
    component: () => import("@/views/sesi-tes-perusahaan/sesi-tes.vue"),
    meta: {
      title: "daftar sesi tes perusahaan",
      requiresAuth: true,
    },
  },
  {
    path: "/detail-sesi-tes/:id",
    name: "detail-sesi-tes",
    component: () =>
      import("@/views/sesi-tes-perusahaan/detail/detail-sesi.vue"),
    meta: {
      title: "detail sesi tes perusahaan",
      requiresAuth: true,
    },
  },
  {
    path: "/add-soal-form",
    name: "add-soal-form",
    component: () => import("@/views/soal/add-soal"),
    meta: {
      title: "detail sesi tes perusahaan",
      requiresAuth: true,
    },
  },
  {
    path: "/event",
    name: "event",
    component: () => import("@/views/event/index"),
    meta: {
      title: "kelola event",
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "router-link-active",
});

const DEFAULT_TITLE = "CMS EKRUTES.ID";
//define route for handle authentication
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.title = to.meta.title + " | CMS EKRUTES" || DEFAULT_TITLE;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["auth/isLoggedIn"] != "") {
      next();
      return;
    }
    next("/");
  } else {
    next();
  }
});

export default router;
