<template>
   <div v-if="$route.meta.requiresAuth">
      <Sidebar />
      <div class="min-h-screen w-full bg-gray-50 !pl-0 sm:!pl-60 dark:bg-gray-800 dark:border-gray-700" id="content">
         <div class="">
            <button
               id="toggler"
               class="m-4 inline-block rounded bg-zinc-800 px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-black hover:shadow-lg focus:bg-black focus:shadow-lg focus:outline-none focus:ring-0 active:bg-zinc-900 active:shadow-lg sm:hidden"
               data-te-sidenav-toggle-ref
               data-te-target="#full-screen-example"
               data-te-ripple-init
               data-te-ripple-color="white"
            >
               <span class="block [&>svg]:h-5 [&>svg]:w-5 [&>svg]:text-white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-5 w-5">
                     <path fill-rule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
                  </svg>
               </span>
            </button>
         </div>
         <div>
            <router-view></router-view>
         </div>
      </div>
   </div>

   <div v-else>
      <router-view></router-view>
   </div>
</template>

<script>
import { reactive } from "vue";
import Sidebar from "./components/Sidebar.vue";
export default {
   name: "App",
   components: {
      Sidebar,
   },
   setup() {
      let data = reactive({
         message: "Rens",
      });

      return {
         data,
      };
   },
};
</script>
