//import global API
import { Api } from "../../api/Api";

const talentdevelopment = {
   namespaced: true,
   state: {
      list: [],
      listPeserta: [],
      listFeedback: [],
      empty: "",
   },
   mutations: {
      ADD_SUCCESS(state, list) {
         state.list = list;
      },
      ADD_PESERTA(state, list) {
         state.listPeserta = list;
      },
      ADD_FEEDBACK(state, list) {
         state.listFeedback = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      list({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`talent-development?page=${data.page}&per_page=${data.per_page}`)
               .then((response) => {
                  const list = response.data;
                  const listdata = response.data.data;
                  commit("ADD_SUCCESS", listdata);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      peserta({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`talent-development/peserta?id_talent_development=${data.id}&status=${data.status}&page=${data.page}`)
               .then((response) => {
                  const list = response.data.data.result;
                  const listdata = response.data.data.result.data;
                  commit("ADD_PESERTA", listdata);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      feedback({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`talent-development/peserta?id_talent_development=${data.id}&status=${data.status}&page=${data.page}`)
               .then((response) => {
                  const list = response.data.data.result;
                  const listdata = response.data.data.result.data;
                  commit("ADD_FEEDBACK", listdata);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      getPromo() {
         return new Promise((resolve, reject) => {
            Api.get(`konten-banner/38`)
               .then((response) => {
                  resolve(response.data.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      setPromo({commit},data) {
         return new Promise((resolve, reject) => {
            Api.put(`konten-banner/38`, data)
               .then((response) => {
                  commit('EMPTY',"")
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      exportPeserta({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.defaults.responseType = "blob"
            Api.get(`talent-development/export-peserta?id_talent_development=${data.id_talent_development}&status=${data.status}&per_page=999`)
               .then((response) => {
                  commit('EMPTY', "")
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
};
export default talentdevelopment;
