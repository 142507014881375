import { createStore } from "vuex";
import admin from "./module/admin";
import auth from "./module/auth";
import dashboard from "./module/dashboard";
import event from "./module/event";
import hr from "./module/hr";
import master from "./module/master";
import mini_quiz from "./module/mini_quiz";
import mitra from "./module/mitra";
import monitoring from "./module/monitoring";
import notification from "./module/notification";
import talent from "./module/talent";
import talentdevelopment from "./module/talentdevelopment";
import sekolah from "./module/sekolah";
import sesites from "./module/sesites";
import siswa from "./module/siswa";
import skoring from "./module/skoring";
import utility from "./module/utility";
import verifikasiAkun from "./module/verifikasiAkun";
import webinar from "./module/webinar";
import kupon from "./module/kupon";

export default createStore({
   modules: {
      admin,
      auth,
      dashboard,
      event,
      hr,
      master,
      mini_quiz,
      mitra,
      monitoring,
      notification,
      talent,
      talentdevelopment,
      sekolah,
      sesites,
      siswa,
      skoring,
      utility,
      verifikasiAkun,
      webinar,
      kupon
   },
});
