//import global API
import { Api, Api2 } from "../../api/Api";

const dashboard = {
    namespaced: true,
    state: {
        warna: ["#54bebe", "#76c8c8", "#98d1d1", "#badbdb", "#dedad2"],
        useroverview: {
            total: '',
            label: [],
            number: []
        },
        item: [],
        occur_search: []
    },
    mutations: {
        EMPTY(state, string) {
            state.empty = string
        },
        SIMPAN_WARNA(state, data) {
            state.warna = data
        },
        SET_USEROVERVIEW(state, res) {
            state.useroverview.label = res.label
            state.useroverview.number = res.number
            state.useroverview.total = res.total
        },
        SET_ITEM(state, item) {
            state.item = item
        },
        SET_OCCUR(state, occur) {
            state.occur_search = occur
        }
    },
    actions: {
        growth({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`summary/total-growth?type=${data.type}&kategori=${data.kategori}&start_date=${data.start_date}&end_date=${data.end_date}`).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        summary({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`summary/total?type=${data.type}&before=${data.is_now}&kategori=${data.kategori}&start_date=${data.start_date}&end_date=${data.end_date}`).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        summary_user({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`summary/list?page=${data.page}&per_page=${data.per_page}&type=${data.type}&before=${data.is_now}&kategori=${data.kategori}&start_date=${data.start_date}&end_date=${data.end_date}`).then(response => {
                    commit('SET_ITEM', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        useroverview({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`summary/total?type=${data.type}&before=${data.is_now}&kategori=${data.kategori}&start_date=${data.start_date}&end_date=${data.end_date}`).then(response => {
                    commit('SET_USEROVERVIEW', response.data.data)
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        occur_search({ commit }) {
            return new Promise((resolve, reject) => {
                Api2.get(`industry/user/search/occur`).then(response => {
                    commit('SET_OCCUR', response.data.data)
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        gender({commit}, data) {
            return new Promise((resolve, reject) => {
                Api.get(`summary/gender?kategori=${data.kategori}&start_date=${data.start_date}&end_date=${data.end_date}&label=${data.label}`).then(response => {
                    commit("EMPTY","")
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        age({commit}, data) {
            return new Promise((resolve, reject) => {
                Api.get(`summary/age?kategori=${data.kategori}&start_date=${data.start_date}&end_date=${data.end_date}&label=${data.label}`).then(response => {
                    commit("EMPTY","")
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        useroverview(state) {
            return state.useroverview
        },
        warna(state) {
            return state.warna
        },
        getitem(state) {
            return state.item
        },
        getoccur(state) {
            return state.occur_search
        }
    }

}
export default dashboard