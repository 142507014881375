//import global API
import { Api } from '../../api/Api'

const skoring = {
    namespaced: true,
    state: {
        listSkoring: [],
        empty: ''
    },
    mutations: {
        ADD_SUCCESS(state, list) {
            state.listSkoring = list
        },
        EMPTY(state, string) {
            state.empty = string
        }
    },
    actions: {
        tambahSkoring({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('skoring', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        listSkoring({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`skoring?search=${data.search}&f_kategori=${data.id}&page=${data.page}`, data).then(response => {
                    const list = response.data.data
                    const listSkor = response.data.data.result
                    commit('ADD_SUCCESS', listSkor)
                    resolve(list)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        facetsubfacet({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`facet?f_kategori=${data.id}&facet=${data.facet}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        list(state) {
            return state.listSkoring
        },
    }

}
export default skoring