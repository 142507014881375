import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementPlus from "element-plus";
import "element-plus/dist/index.css";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import mixins from "./mixins";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import { LoadingPlugin } from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

import { VueTruncateHtml } from "vue3-truncate-html";
import VueNextSelect from "vue-next-select";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faChevronDown, faChevronLeft, faChevronRight, faGears, faHouse, faRightFromBracket, faUser, faUsers, faBell, faPersonCircleCheck, faTv, faCircleInfo, faHandshake } from "@fortawesome/free-solid-svg-icons";
import { fas } from '@fortawesome/free-solid-svg-icons';
library.add(faHouse, faUser, faChevronDown, faUsers, faGears, faRightFromBracket, faChevronLeft, faChevronRight, faBell, faPersonCircleCheck, faTv, faCircleInfo, faHandshake, fas);

import "./index.css";
import VueApexCharts from "vue3-apexcharts";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
const options = {};

import VueNumericInput from "vue-numeric-input";
import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
import JsonExcel from "vue-json-excel";

import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

import VueExcelXlsx from "vue-excel-xlsx";
import { quillEditor } from "vue3-quill";
import tooltip from "./assets/tooltip.js";
import "@/assets/tooltip.css"
// import vSelect from "vue-select-3";
// import VueNextSelect from 'vue-next-select'
import vSelect from 'vue-select' //import vue-select
import "vue-select/dist/vue-select.css"; //import css vue-select

const app = createApp(App);
app.config.perfomance = true
app.directive("tooltip", tooltip);
app.use(VueNumericInput);
app.use(Donut);
// app.component("v-select", vSelect);
// app.component('vue-select', VueNextSelect)
app.use(JsonViewer);
app.component('v-select', vSelect)
app.use(quillEditor);
app.use(VueExcelXlsx);
app.use(VueAwesomePaginate);
app.component("downloadExcel", JsonExcel);
app.component("VueCtkDateTimePicker", VueCtkDateTimePicker);
app.component("VueDatePicker", VueDatePicker);
app.component("vue-select", VueNextSelect);
app.component("vue-truncate-html", VueTruncateHtml);
app.use(VueApexCharts);
app.component("font-awesome-icon", FontAwesomeIcon);
app.use(ElementPlus);
app.mixin(mixins);
app.use(Toast, options);
app.use(router);
app.use(VueSweetalert2);
app.use(store);
app.use(LoadingPlugin);

app.mount("#app");
