//import global API
// import Api from '../../api/Api'
import { Api } from "../../api/Api";

const admin = {
    namespaced: true,
    state: {
        list: [],
        empty: ''
    },
    mutations: {
        ADD_SUCCESS(state, list) {
            state.list = list
        },
        EMPTY(state, string) {
            state.empty = string
        }
    },
    actions: {
        list({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`manage-admin?page=${data.page}&search=${data.search}&status_active=${data.status}&role_id=${data.status_role}`).then(response => {
                    const list = response.data
                    const listAdmin = response.data.data
                    commit('ADD_SUCCESS', listAdmin)
                    resolve(list)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        create({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`manage-admin`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        update({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`manage-admin/${data.id}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ubah_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`manage-admin/${data.link}/${data.id}`).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        delete({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`manage-admin/delete/${data.id}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        list(state) {
            return state.list
        },
    }

}
export default admin