//import global API
import { Api } from '../../api/Api'

const talent = {
    namespaced: true,
    state: {
        list_talent: [],
        empty: ''
    },
    mutations: {
        ADD_SUCCESS(state, list) {
            state.list_talent = list
        },
        EMPTY(state, string) {
            state.empty = string
        }
    },
    actions: {
        list({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`talent?page=${data.page}&per_page=${data.per_page}&search=${data.search}&is_active=${data.is_active}&kategori=${data.kategori}&id_pengalaman=${data.id_pengalaman}&id_sesi=${data.id_sesi}&start_range=${data.start_range}&end_range=${data.end_range}&start_basic_test=${data.start_basic_test}&end_basic_test=${data.end_basic_test}&start_date=${data.start}&end_date=${data.end}&thn_pengalaman=${data.thn_pengalaman}&jenjang_pendidikan=${data.jenjang_pendidikan}&tahun_kelulusan=${data.tahun_kelulusan}`).then(response => {
                    const list = response.data
                    const list_talent = response.data.data.result
                    commit('ADD_SUCCESS', list_talent)
                    resolve(list)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        pengalaman() {
            return new Promise((resolve, reject) => {
                Api.get(`get-list-pengalaman`).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },

        getinfodeletedakun({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.delete(`user/${data.id_login}`).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deletedakun({ commit }, data) {
            const qs = require('qs');
            const data1 = {
                is_delete: 1,
            };
            const formData = qs.stringify(data1);
            
            return new Promise((resolve, reject) => {
                Api.delete(`user/${data.id_login}`,{
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded', // Set the content type
                    },
                    data: formData
                }).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data.message)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        downloadReport({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`token`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        loginuser({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`login/${data.id}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        add_talent({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`talent`, data).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ubah_status_aktif({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`talent/${data.link}`, {
                    id_talent: data.id_talent
                }).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        reset_basic_tes({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`talent/reset_basic_test`, {
                    id_talent: data.id_talent
                }).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        export({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`talent/export?search=${data.search}`).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        detail_kelengkapan_profile({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`talent/detail?id_user=${data.id_user}`).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        }
    },
    getters: {
        list(state) {
            return state.list_talent
        },
    }

}
export default talent