//import global API
import { Api } from "../../api/Api";

const siswa = {
   namespaced: true,
   state: {
      list_siswa: [],
      sesi_siswa: [],
      empty: "",
   },
   mutations: {
      ADD_SUCCESS(state, list) {
         state.list_siswa = list;
      },
      SET_SESI_SISWA(state, list) {
         state.sesi_siswa = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      list({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`siswa?page=${data.page}&search=${data.search}&is_active=${data.is_active}&level=${data.level}`)
               .then((response) => {
                  const list = response.data;
                  const list_siswa = response.data.data.result;
                  commit("ADD_SUCCESS", list_siswa);
                  resolve(list);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      get_list_sesi_user({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`user/${data.id_siswa}/sesi-test`)
               .then((response) => {
                  commit('SET_SESI_SISWA', response.data.data)
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      reset_sesi_tes({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`sesi-test/${data.id}/reset`)
               .then((response) => {
                  commit('EMPTY', "")
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      list(state) {
         return state.list_siswa;
      },
      sesi_siswa(state) {
         return state.sesi_siswa;
      },
   },
};
export default siswa;
