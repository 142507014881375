//import global API
import { Api, Api2 } from "../../api/Api";

const hr = {
    namespaced: true,
    state: {
        list_hr: [],
        provinsi:[],
        kota:[],
        kecamatan:[],
        sesi: [],
        empty: ''
    },
    mutations: {
        ADD_SUCCESS(state, list) {
            state.list_hr = list
        },
        SET_SESI(state, list) {
            state.sesi = list
        },
        SET_PROVINSI(state, list) {
            state.provinsi = list
        },
        SET_KOTA(state, list) {
            state.kota = list
        },
        SET_KECAMATAN(state, list) {
            state.kecamatan = list
        },
        EMPTY(state, string) {
            state.empty = string
        }
    },
    actions: {
        list({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`perusahaan?page=${data.page}&per_page=${data.per_page}&search=${data.search}&is_active=${data.is_active}&start_date=${data.start}&end_date=${data.end}&last_login=${data.status_login}&is_univ=${data.is_univ}`).then(response => {
                    const list = response.data
                    const list_hr = response.data.data.result
                    commit('ADD_SUCCESS', list_hr)
                    resolve(list)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        sesi({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`perusahaan/${data.id_perusahaan}/sesi-test?page=${data.page}&per_page=${data.per_page}`).then(response => {
                    commit('SET_SESI', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getProvinsi({ commit }) {
            return new Promise((resolve, reject) => {
                Api2.get(`industry/get-provinsi`).then(response => {
                    commit('SET_PROVINSI', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getKota({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api2.get(`industry/get-kota?id_provinsi=${data.id_provinsi}`).then(response => {
                    commit('SET_KOTA', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getKecamatan({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api2.get(`industry/get-kecamatan?id_kota=${data.id_kota}`).then(response => {
                    commit('SET_KECAMATAN', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        export({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.defaults.responseType = "blob"
                Api.get(`perusahaan/export?page=${data.page}&search=${data.search}&is_active=${data.is_active}&start_date=${data.start}&end_date=${data.end}&last_login=${data.status_login}&is_univ=${data.is_univ}`).then(response => {

                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        download_sesi({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`perusahaan/${data.id_perusahaan}/sesi-test/${data.id_sesi}/export`).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        ubah_status({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`perusahaan/${data.link}`, {
                    id_perusahaan: data.id_hr
                }).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        add_subscription({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`perusahaan/subscription`, data).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        hrToSekolah({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`perusahaan/convert/sekolah`, data).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response.data.message)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        resetkoin({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`transaksi/coins/reset`, data).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response.data.message)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        add_hr({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`perusahaan`, data).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        add_koin({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`perusahaan/topup`, data).then(response => {
                    commit('ADD_SUCCESS', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        subscription() {
            return new Promise((resolve, reject) => {
                Api.get(`subscription`).then(response => {
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        list(state) {
            return state.list_hr
        },
        sesi(state) {
            return state.sesi
        },
        provinsi(state) {
            return state.provinsi
        },
        kota(state) {
            return state.kota
        },
        kecamatan(state) {
            return state.kecamatan
        },
    }

}
export default hr