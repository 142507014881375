//import global API
import { Api } from "../../api/Api";

const event = {
   namespaced: true,
   state: {
      event: [],
      empty: "",
   },
   mutations: {
      SET_EVENT(state, list) {
         state.event = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      store({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`event`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.put(`event/${data.id}`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      delete({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.delete(`event/${data.id}`)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      list({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`event?page=${data.page}&search=${data.search}`)
               .then((response) => {
                  commit("SET_EVENT", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      getEvent(state) {
         return state.event;
      },
   },
};
export default event;
