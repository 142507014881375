//import global API
import { Api } from "../../api/Api";

const kupon = {
   namespaced: true,
   state: {
      kupon: [],
      empty: "",
   },
   mutations: {
      SET_KUPON(state, list) {
         state.kupon = list;
      },
      EMPTY(state, string) {
         state.empty = string;
      },
   },
   actions: {
      store({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.post(`kupon`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      update({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.put(`kupon/${data.id}`, data)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      delete({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.delete(`kupon/${data.id}`)
               .then((response) => {
                  commit("EMPTY", "");
                  resolve(response);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
      list({ commit }, data) {
         return new Promise((resolve, reject) => {
            Api.get(`kupon?page=${data.page}&search=${data.search}`)
               .then((response) => {
                  commit("SET_KUPON", response.data.data);
                  resolve(response.data);
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },
   },
   getters: {
      getKupon(state) {
         return state.kupon;
      },
   },
};
export default kupon;
