//import global API
import { Api } from '../../api/Api'

const mini_quiz = {
    namespaced: true,
    state: {
        list_mini_quiz: [],
        list_soal: [],
        facet_by_id: [],
        default_timer_section: [],
        empty: ''
    },
    mutations: {
        ADD_SUCCESS(state, list) {
            state.list_mini_quiz = list
        },
        ADD_SUCCESS_SOAL(state, list) {
            state.list_soal = list
        },
        ADD_FACET_ID(state, facet) {
            state.facet_by_id = facet
        },
        SET_DEFAULT_TIMER_SECTION(state, default_timer_section) {
            state.default_timer_section = default_timer_section
        },
        EMPTY(state, string) {
            state.empty = string
        }
    },
    actions: {
        list({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`kategori-test?page=${data.page}&search=${data.search}`).then(response => {
                    const list = response.data.data
                    const list_quiz = response.data.data.data
                    commit('ADD_SUCCESS', list_quiz)
                    resolve(list)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        listSoal({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`quiz/list-soal?id_quiz=${data.id}&search=${data.search}`).then(response => {
                    commit('ADD_SUCCESS_SOAL', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        capture({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`talent/cp?search=${data.search}&kode=${data.kode}&page=${data.page}&per_page=${data.per_page}&jenis_kelamin=${data.jenis_kelamin}&type=${data.type}&tahun_lahir=${data.tahun_lahir}`).then(response => {
                    console.log(response.data.data)
                    commit('ADD_SUCCESS_SOAL', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        kodeFacet({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`facet/list?f_kategori=${data.id}`).then(response => {
                    commit('ADD_FACET_ID', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        section({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`kategori/timer-section?f_kategori=${data.id}`).then(response => {

                    commit('SET_DEFAULT_TIMER_SECTION', response.data.data[0].default_timer_section)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        dataJenis() {
            return new Promise((resolve, reject) => {
                Api.get(`kategori-test/jenis`).then(response => {
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        add({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('kategori-test/create', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        add_new_soal({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('mini-quiz', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        hapusSoal({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`quiz/delete-soal-jawaban/${data.id}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        importSoal({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('quiz/upload', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        tambahSoal({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('soal', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        deactive({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('quiz/deactive', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        active({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post('quiz/activate', data).then(response => {
                    commit('EMPTY', '')
                    resolve(response)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        detail({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`kategori-test/detail/${data.id}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        update({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.post(`kategori-test/update/${data.id}`, data).then(response => {
                    commit('EMPTY', '')
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        list(state) {
            return state.list_mini_quiz
        },
        facet_by_id(state) {
            return state.facet_by_id
        },
        default_timer_section(state) {
            return state.default_timer_section
        },
    }

}
export default mini_quiz