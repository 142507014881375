//import global API
import { Api } from "../../api/Api";

const sesites = {
    namespaced: true,
    state: {
        empty: '',
        sesi: [],
        detail_sesi: {}
    },
    mutations: {
        EMPTY(state) {
            state.empty = ''
        },
        SET_SESI(state, sesi) {
            state.sesi = sesi
        },
        SET_DETAIL_SESI(state, detail_sesi) {
            state.detail_sesi = detail_sesi
        }
    },
    actions: {
        get_sesi_tes_perusahaan({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`sesi-test?page=${data.page}&per_page=${data.per_page}&search=${data.search}&start_date=${data.start_date}&end_date=${data.end_date}&is_sekolah=${data.is_sekolah}`).then(response => {
                    commit('SET_SESI', response.data.data)
                    resolve(response.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        detailsestes({ commit }, data) {
            return new Promise((resolve, reject) => {
                Api.get(`sesi-test/detail?sesi_id=${data.id}&field_study=&search=${data.search}&jenjang_pendidikan=&jenis_kelamin=&id_pengalaman=&id_posisi=&id_kota=&id_provinsi=&min_gaji=&max_gaji=&usia_min=&usia_max=&status=${data.status}&page=${data.page}&per_page=12`).then(response => {
                    commit('SET_DETAIL_SESI', response.data.data)
                    resolve(response.data.data)
                }).catch(error => {
                    reject(error)
                })
            })
        },
    },
    getters: {
        getsesi(state) {
            return state.sesi
        },
        getdetailsesi(state) {
            return state.detail_sesi
        },
    }

}
export default sesites