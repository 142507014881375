import axios from "axios";

const token = localStorage.getItem("token");
const Api = axios.create({
   baseURL: process.env.VUE_APP_endpoint,
});

const Api2 = axios.create({
   baseURL: process.env.VUE_APP_endpoint2,
});

Api.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

if (token != "") {
   Api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
   Api2.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export { Api, Api2 };
